export const translations = {
    about : {
        'sl' : 'O meni'
    },
    introduction : {
        'sl' : `Moje ime je Denis Kotnik, magistriral sem na Fakulteti za računalništvo in informatiko na Univerzi v Ljubljani s področja umetne inteligence, trenutno se izobražujem za psihodinamskega psihoterapevta, sicer pa me zanimata psihoanaliza in filozofija.
        <br><br>
        Vem, da so čustva tista, ki človeku dajejo pogum, da sledi idejam, zato sebe v dobrem pogovoru trudim postavljati pod vprašaj. Navdušuje me ideja razsvetljenstva ter ljudje, ki jo zastopajo in ki iščejo samega sebe zato, da bi okrepili dober, pristni, avtentični odnos do Drugega. Blizu so mi misleci, kot so Sokrat, Karl Marx, Sigmund Freud, Alain Badiou, René Descartes in drugi.`
    },
    opinions : {
        'sl' : `Ljudje že od vekomaj radi ponavljajo, da imajo pravico do izražanja mnenj. Slednje ne terja poglobljenega razmišljanja oz. energije in časa. Če vsak o neki stvari mimogrede izrazi mnenje, nastane v hipu ogromno mnenj. <i>Komu ta tiranija mnenj koristi? Kam pridemo s tem?</i>
        <br><br>
        <i>Zakaj ljudje to počnejo?</i> Mnenja radi izražajo, saj je to láhko, varno in udobno počétje. Človek ne more sam potrjevati svoje lastne identitete - za to nujno potrebuje drugega človeka. Pri tem so mnenja lahko odlično pomagalo, namreč ljudje se običajno radi pogovarjajo z ljudmi, ki imajo podobna mnenja. Mnenja ohranjajo <i>partikularnosti</i> (nekaj, kar se tiče posameznika, ni pa nujno, da se tiče še koga drugega; nekaj, kar je lahko resnično v odnosu do nekoga, ne pa do vseh).
        <br><br>
        Mnenja ohranjajo tudi hierarhične družbene odnose, zato imajo še posebej tisti <i>zgoraj</i> radi mnenja tistih <i>spodaj</i>. Ko želijo spodnji izraziti mnenje pred zgornjimi, pa se jim včasih zatakne, namreč izražanje mnenj je lahko tudi nevarno. Ljudje refleksno vedo, da ni varno govoriti resnice, ko so poleg avtoritete. Tvegajo, da izrazijo nekaj, kar ni mnenje, temveč nekaj <i>univerzalnega</i> (kar se vedno tiče vseh; kar je vedno resnično v odnosu do vsakega). Slednje nujno pod vprašaj postavlja razmerja, v katerih se nekdo prepozna kot hlapec, drugi pa kot gospodar. Z drugimi besedami, razmišljanje nujno rojeva kritični dvom v avtoritete - gre za univerzalni skepticizem.
        <br><br>
        Zgoraj zapisano ve (oz. je vedel) tudi filozof Sokrat. Ve, da so mnenja v odnosu do resnice prekratka, pa če so še tako sofisticirana. Da izražanje resnic in vztrajanje v njih terja <i>pogum</i> in da pogumno delovanje pomeni zmanjševanje razlike med tem, kaj človek ve in tem, kaj počne. Ve, da univerzalnost pod vprašaj postavlja partikularnosti ter da brez omejevanja drugega ni prostora za prvo.
        <br>
        Sokrat pred pravico do izražanja mnenj zagovarja dolžnost do raziskovanja in razmišljanja. Do raziskovanja, v katerem je raziskovalec pripravljen sebe postavljati pod vprašaj, in razmišljanja, ki pa je delo v strogem pomenu besede. Razmišljanje je sicer univerzalno - nihče ne more narediti nič, ne da bi razmišljal - pa vendar je brez raziskovanja, kakršnega sem omenil, premalo (Hitler je namreč tudi veliko razmišljal).
        <br><br>
        V dobi podatkov, ko se mnenja generirajo na podlagi mnenj, sta raziskovanje in razmišljanje še posebej pomembna. Vedno je bilo tako. Namreč le razmišljanje lahko pripelje do tega, da se univerzalnost izrazi skozi naše partikularnosti. Le razmišljanje ustvarja mejo med ljubiteljem mnenj in ljubiteljem modrosti in ta meja je v nas samih. Le razmišljanje omejuje in posledično osvobaja človeka (saj je svoboda omejena z resnico), ustvarja ter ločuje dobro in zlo, ki sta tudi v nas samih.
        <br><br>
        Berem Dušana Rutarja. <i>Ptica na nebu ni svobodna; ta je le ujetnica svojih instinktov. Svoboden je človek, ko razume in dojema, kakšno je njegovo poslanstvo na tem svetu.</i>`
    },
    philosophy: {
        'sl' : `Filozofija (<i>filia</i> oz. ljubezen in <i>sofia</i> oz. modrost) je diskurz, v katerem prevladujejo predvsem dobra vprašanja. Ni privilegirana le za bogate, izobražene, vplivne, slavne ali študente filozofije, temveč za vse, ki so zmožni za razmišljanje, s tem pa tudi za resnico. Njen ultimativni cilj je jasno razmejevanje med mnenji in resnico. Ne vodi v še več mnenj, v še več blaga na svobodnih trgih ali v reševanje kapitalizma. Filozofija vodi v demokratično (demokracija ne tolerira mnenj, saj so ta nasprotna resnici) egalitarno (egalitarnost kot odsotnost kakršnih koli oblik dominance v človeških odnosih) življenje v svetu, ki ni v privatni lasti.
        <br><br>
        Niso le dobra filozofska vprašanja tista, ki ljudem odpirajo prostor za razmislek o <i>pravem življenju</i>. Pomembna je tudi osebnost tistega, ki vabi k razmišljanju. Pogumen filozof vabi k življenju, ki ni le še ena izmed mnogokrat prehojenih poti in za katerega se splača živeti, kot pravi Badiu. Vabi k zavračanju tistega, kar človek domnevno je, kot pravi Foucault. Vabi k osvobajanju od idej, ki obvladujejo svet in k osvoboditvi pridobljenih zunanjih vzorov sreče, ki so splošne narave in so običajno nezdružljivi s posameznikovo osebnostjo, kot pravi Galimberti. Vabi k etični dolžnosti posameznika, ki jo ima Aristotel za najvišji življenjski smoter. 
        <br>
        V tem filozofovem povabilu obstaja nekaj, zaradi česar je nadvse smiselno dajati prednost najzahtevnejšim in najnapornejšim potem pred najlažjimi in najlagodnejšimi.
        <br><br>
        Sokrat je bil obsojen na smrt, ker je pokvaril mladino. To pomeni, da je ljudi navdušil nad razmišljanjem, zaradi česar so podvomili v življenje, ki ga vodijo denar, moč in užitek. Ker so odprtomiselni, pogumni in vztrajni ljudje nevarni za hierarhije, je moral filozof umreti. Filozofija za sedaj pa je tudi natanko to, kar je vedno za nek trenutek bila - nevarna. Kot ljubezen, ki pa je najmočnejša partikularna izkušnja univerzalnega.
        <br><br>
        <i>Če ljubezni ne postaviš na prvo mesto, ne boš nikoli vedel, kaj je filozofija. –Alain Badiou</i>
        `
    },
    capitalism: {
        'sl' : `Obstaja trg, na katerem je mogoče kupiti delovno silo. Kupljena delovna sila pomeni kupljenega človeka, saj ne obstaja delovna sila brez človeka. Ta za kupca predstavlja <i>človeški vir</i> (angl. <i>human resource</i>) vrednosti. Kupec lahko človeku, ki ga je kupil, odvzema več vrednosti, kot pa mu jo vrača. Da to počne, mora z njim upravljati - ko je človek kupljen, je tudi upravljan. Upravljan je s strani upravljavcev človeških virov (angl. <i>human resource management</i>) oz. menedžerjev, ki od neke točke upravljajo tudi medsebojne odnose in eksistence upravljanih, saj je eksistenca tudi del vsakega človeka in vsak človek je v medsebojnih odnosih.
        <br><br>
        <i>Kako so ljudje upravljani?</i>
        <br>
        Obstajajo družbeno gospodarske ureditve, ki težijo k takšnemu upravljanju ljudi, ki iz njih izvablja najboljše, inteligenco, mentalno-kognitivne in fizične sposobnosti ter z dobrimi nameni, v dobrobit naravi, skupnosti, ustvarjanju trajnostne prihodnosti, prispevanju k znanosti, varnosti, zdravju ljudi itd., kjer se upravljanje lahko spremeni v sodelovanje.
        Kapitalizem ne spada v to skupino, saj deluje le za elito, ljudje pa na žalost še vedno verjamejo, da bo nekega dne začel delovati tudi za njih. V kapitalizmu kapitalisti kupujejo delavce in z njimi upravljajo na natanko en sam način - tako, da mu prinašajo profit. Zadovoljstvo ter blagostanje ljudi, etika, morala in resnica so v primerjavi s kopičenjem profita nepomembne ali celo nezaželene zadeve. Ko se pred naftnimi korporacijami pojavijo znanstveniki z objektivnimi in empiričnimi dokazi, da črpanje nafte na dolgi rok ni dobro za nikogar, so preslišani oz. na koncu se ne spremeni nič. V svetu, kjer je vsak individualist osamljeno zase, kjer so zakoni skupnosti le omejitev do še več profita, si kapitalisti prizadevajo cel svet spremeniti (v procesu imenovanem <i>globalizacija</i>) v tako skupnost, ki ji vlada natanko en zakon: zakon profitabilnosti.
        <br><br>
        <i>Do katere mere so ljudje pripravljeni biti upravljani?</i>
        <br>
        Za <i>zgornje</i> ni dobro, da <i>spodnji</i> preveč vedo, namreč resnica je v nasprotju z hierarhičnimi medsebojnimi odnosi, na katerih temelji kapitalizem. V njih se na neki točki vsak prepozna ali kot hlapec ali pa kot gospodar. Ko zgornji dobijo občutek, da bi jim vednost spodnjih lahko zmanjšala profit, spodnje omejijo pri raziskovanju in razmišljanju - ustvarijo <i>poslovne skrivnosti</i>.
        <br><br>
        Človek po svoji naravi ne želi biti upravljan (morebitna želja po tem je le simptom nečesa), saj je svoboden, zna misliti in slediti idejam - če je kaj značilno za človeka, je to to. Misliti pomeni zelo konkretno in praktično zagovarjati egalitarnost, saj je misel sama po sebi egalitarna, namreč ne prenese, da bi z njo kdorkoli upravljal. Spodnji se zgornjemu preda, ko ne želi več zastopati egalitarnosti, radikalnosti, emancipatoričnosti, univerzalnega dvoma, kritičnosti, ko ne želi več misliti. Vztrajanje v resnici ni profitabilno, zato je filozofija kontradiktorna kapitalizmu.
        <br><br>
        Berem filozofa Karla Marxa. <i>Ko se bodo delavci resnično zavedali svoje vloge, bo kapitalizem propadel.</i>`
    },
    postmodernism: {
        'sl' : `V svetu mnenj, v katerem nihče ne opravlja dolžnosti kritičnega razmišljanja, so ljudje zmedeni, dezorientirani, ranjeni ter zaslepljeni s kapitalistično mero uspeha. So že toliko upravljani, da verjamejo, da so tudi eksistenca, duhovnost ter medosebni odnosi eno samo upravljanje, zato želijo upravljati tudi npr. svoja čustva, namesto da bi jih razumeli, mislili ter izražali. Resnica v takem svetu je nekaj oddaljenega, abstraktnega, preveč <i>filozofskega</i>. Ljudje nimajo nobenega dobrega razloga, da bi razmišljali (ter posledično bili odgovorni), saj se povsod ponujajo že vnaprej izdelane interpretacije, nasveti, navodila za življenje ter <i>sanje</i>. Ljudje hočejo nekaj čim krajšega ter čim bolj pragmatičnega, če je mogoče še profitabilnega, zato so še posebej uspešni trenerji, motivatorji, astrologi, jogiji, vedeževalci, vidci prejšnjih ter prihodnjih življenj, bioenergetiki, upravljalci kozmičnih energij, terapevti s kristali, čakrami, mandalami, gongi itd. Neprestano se ustvarjajo nove religije oz. vraževerstva, ki pa so v bistvu le zelo stare sofistike z novimi imeni. V svetu mnenj postane pomembna magija, šamanizem dobi nova krila, bioenergija pa slej kot prej vsakodnevno potrošno blago, skrivne moči nadčutnega nadomeščajo sposobnost racionalnega in kritičnega razmišljanja. Ljudje se v takem svetu sporazumevajo preko novih višjih dimenzij, ki jih jezik ne more opisati, saj so preveč kompleksne in nerazumljive, intuicija in telepatija postaneta modna hita. Vsa ta sofistika pa ne ve, da je postala novo orodje dominacije in nadzora nad posamezniki, kraj vedno večjih dobičkov, s pomočjo katerih se ohranjajo oblastni odnosi še naprej.
        <br><br>
        Sofistiko za namene kopičenja profita s pridom izkorišča tudi kapitalizem. Bankirji, ekonomisti, direktorji, menedžerji v svoja podjetja med svoje zaposlene vabijo motivatorje ter guruje, da jih učijo čuječnosti, meditacij ter senzibilnosti. Podjetja se hvalijo z nazivi, kot je <i>flat company</i>, češ da so vsi <i>na istem čolnu</i>, v katerem hierarhična razmerja ne obstajajo. Na zunaj sicer izgleda tako, v resnici pa je upravljanje ljudi danes tako prikrito, da pogosto niti upravljani (zavedno) ne vedo, da so upravljani.
        <br><br>
        Znanstveniki iz celega sveta v zadnjih desetletjih iz objektivnih analiz ter meritev o npr. črpanju nafte, sekanju tropskih gozdov, odpiranju rudnikov in onesnaževanju planeta prihajajo do enih in istih zaključkov ter opozarjajo, da je potrebno razmišljati o spremembah. V globalnih podjetjih je zato danes popularen žargon, kot je <i>zeleno, trajnostni razvoj, etično, podnebno nevtralno, brezogljično, ekološko, pošteno</i> itd. Ljudem se danes v postmodernizmu spontano zdi, da so kapitalisti oz. vlagatelji tam zaradi drugega razloga, kot je profitabilnost.
        <br><br>
        Kapitalizem močno izkorišča tudi psihologijo. Ljudje v marketingu se učijo zadev, ki se jih sicer učijo psihologi, le z namenom, da bodo profiti še večji. Tako lahko nastane dobra postmoderna reklama, npr. <i>"Mi ne proizvajamo avtomobilov, temveč občutke."</i> ali pa <i>"V tem avtu se boste počutili takole."</i> Kapitalizem nas želi čim bolj oropati tudi svojih lastnih občutkov ter kreativnosti tako, da nam ves čas servira premišljene ideje, interpretacije, storitve, doživetja, izkušnje. Dokumentarni film <i>Stoletje sebstva</i> (angl. <i>Century of self</i>) pripoveduje o tem, kako je kapitalizem s pomočjo psihoanalize že v prejšnjem stoletju umetno ustvarjal želje oz. potrošnike in tako izoblikoval trg <i>neskončnih želja</i>.`
    },
    selfDevelopment: {
        'sl': `<i>Spoznaj samega sebe</i> je bil napis nad vrati Apolonovega templja v Delfih, ki je ob vhodu pričakal obiskovalce. Izrek so si prisvojili mnogi filozofi, s čimer so želeli pokazati, da njihov nauk ni nekaj partikularnega oz. individualnega temveč nekaj, kar pripada univerzalnemu.
        <br><br>
        Danes, v obdobju trenerjev za osebnostno rast, ljudje pozabljajo o pomenu resnice v človekovem življenju. Zgodovinar Christopher Lasch trdi, da so mesto duhovnika, ki je nekdaj skrbel za odrešitev, zamenjali terapevti, ki posamezniku obljubljajo duševno zdravje, nirvano, razsvetljenje, povezanost v eno in življenje v trenutku, vse to brez ozira na dognanja znanosti oz. psihologije, češ da <i>znanost ne more razložiti vsega</i>. To, da znanost ne more razložiti vsega, je trivialno res, saj je negotovost vtkana v samo realnost, poslanstvo znanosti pa je konvergenca k resnici, resnice pa se ne da definirati v obliki izreka, enačbe ali modrosti. Res je tudi, da če se nečesa ne da razložiti z znanostjo, se tega ne da narediti na noben drug način.
        <br><br>
        Ker človekova identiteta ni nastala v vakuumu in kar sama od sebe, ljudje pa si je niso izbrali oz. ustvarili sami, je razumevanje sebe in posledično spreminjanje svoje identitete nekaj, kar se človek ne more iti sam s pomočjo knjig, nasvetov ali meditacije pod cvetočo češnjo. Človekova identiteta je nastala v medsebojnih odnosih, v procesu imenovanem <i>subjektivacija</i>, zato za delo na sebi človek potrebuje več, kot je njegova lastna partikularnost. Potrebuje odnos do nečesa večjega zunaj njega.
        <br><br>
        Da bi razumeli odnos človeka do resnice in do drugega človeka, moramo najprej poskušati razumeti človeka, logiko njegove želje, ter oblastne mehanizme (načine, kako se vzpostavljajo razmerja pod in nadrejenosti), znotraj katerih človek deluje, pravi Michel Foucault. Razumevanje omenjenega nujno terja psihoanalitičen pogled na človeka (psihoanaliza je teorija o nezavednem). Vsak človek ima potrebo po pogovarjanju z drugim človekom in vsak človek želi željo drugega (ne vsakega) človeka. Z drugimi besedami, želi biti želen, prepoznan, potrjen od drugega, ljubljen; želja pa je želja po vrednosti, ne po tem ali onem objektu. Ali je samoumevno, da človek nemudoma in strastno pograbi ter zaživi resnico, ko ta v odnosu oz. pogovoru pod vprašaj postavi njegovo lastno identiteto oz. partikularnost? Sigmund Freud se sprašuje se, zakaj ima človek tako močne obrambne mehanizme (zanikanje, potlačitev, kompenzacija, regresija, projekcija, racionalizacija itd.), ki mu branijo do resnice. Ugotavlja, da obstajajo odpori, ki preprečujejo, da bi pripovedovali in sprejemali resnico, da obstaja resnica odporov in obstajajo pogovori o vsem tem. Kako to, da se človek ne bo strinjal z razlago, ki bi mu razblinila njegovo realnost, četudi bi kritični mislec govoril z angelskim glasom direktno iz nebes? Artikuliranje resnice - delo na sebi - je omejevanje svoje partikularnosti oz. odpiranje pandorine skrinjice, zato je lažje pripovedovati mnenja.
        <br><br>
        Erich Fromm se sprašuje, zakaj se potem sploh ukvarjati z resnico, če ta boli? Razmišlja, da spoznanje nje osvobaja, sprošča moči in bistri človekov um, ga dela neodvisnega od malikov, manj živalskega (manj opiranja na čustva, bolečino, instinkte, intuicije in energije) in bolj človeškega (biti podrejen ideji, npr. ideji ljubezni). Resnica bo v pogovoru nekaj sprožila, imela bo nek učinek na vse ljudi v odnosu. Notranja strast do resnice spreminja na način, ki ga opredeljuje izraz <i>osebnostna rast</i>. S tem razumemo, kako ljudje uresničujejo dobro v svojih življenjih. Dobro je vedno univerzalno, ne pa npr. dobro posameznika, kot so nakupovanje, zadovoljevanje želja z dobrinami itd.
        <br><br>
        <i>Kaj lahko človek, ki želi delati na sebi, pričakuje od nekoga, ki je že nekaj naredil na sebi? Kaj lahko človek, ki se želi spremeniti ali ki trpi, pričakuje od nekoga, za katerega predpostavlja, da nekaj ve?</i> Edini pravilni odgovor na to vprašanje je pogovor o resnici. Npr. o trpljenju, življenju, odnosih, problemih, tesnobi, okolju, starših, krivičnem ter odgovore na vprašanja, kot so <i>"Zakaj trpim?", "Kako to, da si ne morem želeti, kar hočem?", "Zakaj nekaj ves čas počnem, čeprav vem, da to ni dobro zame?", "Zakaj se obnašam, kot da nimam izbire?", "Zakaj sem tak, kot sem?", "Kako vem, da nekaj vem?", "Zakaj govorim eno, počnem pa drugo?"</i> itd. Ta, za katerega se predpostavlja, da nekaj o nečem ve, resnice ne more artikulirati v obliki nasvetov, priporočil ali navodil. Resnico lahko nekdo artikulira v pogovoru z dobrimi asokratskimi vprašanji, ki traja in v katerem tisti, ki se želi spremeniti, tudi vztraja ter pod vprašaj postavlja svojo lastno identiteto. Ta, za katerega se predpostavlja, da nekaj ve, lahko naslavlja trpljenje v tistem, ki se želi spremeniti. Torej pripovedovanje resnice kot proces; kot nekaj, kar traja. Tako, kot so trajali pogovori Sokrata z državljani, ko se je poskušal prepričati o svoji nevednosti, ki je posebna oblika vednosti. <i>"Vem, da nič ne vem."</i>
        <br><br>
        Foucalt govori o etiki subjektivnosti kot zmožnosti človeškega bitja, njegove subjektivnosti, da spozna resnico o sebi, o svoji subjektivnosti. Če posameznik poskuša pripovedovati resnico o svoji subjektivnosti, ipso facto poskuša artikulirati resnico o svoji želji in resnico o medsebojnih odnosih. Brez etike subjektivnosti ni osebnostne rasti. Torej osebnostno rasteš le, če te zanima resnica lastne subjektivnosti in okolja, v kateri je nastala. Etika subjektivnosti je v temelju za to, kar imenujemo dobro, kot pravi Dušan Rutar.`
    },
    freeWill: {
        'sl': `Pravijo, da je potrebno <i>slediti</i> svojemu srcu, čustvom in intuiciji ter da vsak zase ve, kaj je najbolje zanj. In da moramo nazaj v naravo, ter da ji je potrebno prisluhniti.
        <br><br>
        Adolf Hitler je sledil svojim sanjam, čustvom ter intuiciji (oz. svoji ideji) in k sreči so ga na njegovi srčni poti ustavili. Kapitalizem s propagando o npr. novem telefonu naslavlja čustva in ne razum potrošnikov. Tudi kadilci, odvisniki od nekaterih drog, mesa in sladkorja se opírajo na omenjene klišeje. Telo jim vedno znova rojeva željo (in ne potrebo) po <i>še več istega</i>. Ljudje brez smisla, pristnosti, prijateljev, samopodobe, samospoštovanja, samozavesti, samozaupanja ali samozavedanja običajno nimajo druge možnosti, kot da se pustijo voditi nečemu v njih samih. Kot da je najboljša referenca o tem, kaj je za njih dobro, v njih že od nekdaj in da je nastala kar sama od sebe.
        <br><br>
        Živali so naravna bitja kot ujetnice sveta instinktov, čustev in občutkov. Pès zato ne more nikoli biti človekov prijatelj v pravem pomenu besede, saj je za prijateljstvo, tako kot za ljubezen, ki ni seks, potrebno več kot le sledenje (zadevam v) sebi. Podrejen bo vodji krdela, pa če le-to vidi v drugem psu, človeku ali pa tjulnu. Pri tem nima izbire.
        <br><br>
        Ljudje smo bitja umetnih svetov, svetov idej, ki jih v odnosih z drugimi ljudmi ustvarjamo z uporabo jezika. Naša ideja o samemu sebi je neprestano odvisna od sočloveka. Smo simbolna bitja, kot pravi Jung. Votline v naravi smo že zdavnaj zapustili, čričkov pa tudi nima smisla poslušati v nedogled.
        <br><br>
        Idejam oz. vednosti se ljudje običajno upirajo oz. jo zavračajo, kot pravita Freud in Platon. Tudi ko že poznajo idejo oz. že imajo vednost, se upirajo, da bi s tem nekaj naredili. Npr. poznajo idejo o globalnem segrevanju planeta (katero zadnja desetletja neprestano dokazuje na tisoče znanstvenikov), vendar se obnašajo, kot da se bo z vso to silno vednostjo nekega dne nekaj naredilo kar samo od sebe, npr. količina CO2 se bo zmanjšala sama od sebe, število zaklanih živali pa tudi. Ko ljudje iščejo razloge, da nečesa ne bi naredili, jih je praviloma strah. Oglasi se srce, ki pravi <i>uff, tega pa ne bi</i>. Sicer ne pravijo, da jih je strah, vendar pred drugimi in pred sabo poskušajo ustvariti vtis, kot da imajo razumske razloge, da neke ideje ne bi zastopali. Včasih se opírajo tudi na intuicijo ter ustvarjajo dogme, saj ne vedo, kaj intuicija (oz. nekaj <i>skrivnostnega</i>) sploh je.
        <br><br>
        Bistvena razlika med človekom in živaljo je ta, da je človek, kot subjekt, kot svobodno bitje, kot izjemno omejeno bitje iz mesa in krvi, sposoben slediti idejam, ki presegajo njegovo partikularnost. Človek se lahko usmeri v tisto smer, kamor hoče neka univerzalna ideja, ki ni njegova, in ne tja, kamor hoče on sam, npr. k profitu in udobju. <i>Kako?</i>
        <br><br>
        Človek lahko eno samo idejo nekega dne vzame zares in dovoli, da ideja oz. pot nekaj z njim naredi. Obstaja namreč neskončna razlika med tem, da človek nekaj o nečem ve in tem, da tisto živi oz. izvrši. Freud pravi, da to ni enostavno in da to nujno terja vsaj dvoje. 
        Prvič, človek se mora nekaj novega naučiti (npr. o sebi). In drugič, nečemu se mora odpovedati (<i>nekaj mora umreti</i>).
        <br><br>
        Človekov um je največja ovira pri učenju o sebi, objektivni realnosti in sočloveku. Zato mora človek na neki točki, če želi še več vedeti o sebi, objektivni realnosti ali sočloveku, na svet (idej) pogledati skozi oči sočloveka. To počne, npr. ko se kot otrok ob učitelju uči o matematiki - ustvarja teoretske koncepte o točki in premici, ki v fizičnem svetu ne obstajata. Človek ne more razumeti ničesar brez teoretskih konceptov. Da bi razumel svoj um, mora tudi ideje o sebi postaviti v odnos z idejami o sebi, ki prihajajo od sočloveka. Psihoanaliza je najboljša praksa za kaj takega. Ko človek tako dovolj časa misli, dobi občutek, da je realnost drugačna, kot je bila do sedaj. Obstaja namreč katarzičen trenutek, ko spozna, kaj je njegovo poslanstvo in se lahko odloči, da bo podrejen neki univerzalni ideji. Postane <i>subjekt</i>. Postane svoboden v samoomejevanju in s tem odgovoren.`
    },
    transfer: {
        'sl': `Če si človek zlomi nogo, ne gre k psu, čeprav lahko pes domnevno začuti bolečino človeka. Prav tako ne gre k avtomehaniku, čeprav je avtomehanik lahko zelo empatičen človek, obenem pa še veliko ve o avtomobilih. Gre k zdravniku, in to natanko zato, ker predpostavlja, da zdravnik nekaj o njem oz. njegovem problemu ve, česar on sam ne ve. Zaradi te predpostavke lahko bolnik
        na zdravnika slika svoje fantazije - npr. <i>pozdravil mi bo nogo</i>. Če tega ne bi predpostavljal, ne bi imel razloga za obisk zdravnika.
        Takšnemu medosebnemu odnosu pravimo transferni odnos. Nujen pogoj zanj je posebna oblika vednosti o drugem. Človeku, ki trpi za depresijo, zdravnik, ki zdravi zlomljene kosti, ne more nuditi pomoči, ki jo potrebuje. In obratno, človek z zlomljeno nogo pač ne gre k psihoterapevtu. Psihoterapevt bolniku jamči za nov poskus odgovarjanja na vprašanje, zaradi katerega se oglasi pri njem, jamči za vednost (o univerzalnem), zaradi česar lahko bolnik nanj prenaša svoje fantazije, ki naj bi jih bil v preteklosti prenašal na svoje skrbnike.
        <br>
        Posebna oblika vednosti je posebna tudi zato, ker lahko obstaja le zaradi človekovega odnosa do univerzalnega. Namreč ni potrebno, da je nekdo strokovnjak vsakega individuuma, da lahko pomaga vsakemu individumu. Vsak človek je drugačen, vendar ima kosti, zato lahko nekdo, ki dovolj ve o kosteh, pomaga vsakemu človeku z zlomljeno nogo. In ker je realnost, kot jo doživlja vsak človek, vedno nujno v odnosu do širše realnosti, je tudi človekova notranja stiska v odnosu do nečesa zunaj njega samega. Zato lahko psihoterapevti oz. tisti, ki dovolj vedo o tem, kako nastane človekova realnost, naslavljajo trpljenje oz. pomagajo zmanjšati trpljenje v drugem kljub temu, da sami nikoli niso trpeli na enak način. Vsak interpretira svet malo drugače, kar pa še ne pomeni, da ljudje nimajo skupnega imenovalca. Vsak človek je namreč v podrejenem odnosu do resnice, pa če mu je všeč ali ne, ali pa če o tem kaj ve, ali ne ve nič.
        <br><br>
        Kapitalizem temelji na odnosih, v katerih se ljudje prepoznavajo kot hlapci ali gospodarji. Ker se človek ne more opreti na svojo identiteto, saj je ta razcepljena, jo opira na transfer. Hlapci so zato hlapci natanko zaradi transfernega odnosa do gospodarjev. Ne obstaja gospostvo nad ljudmi brez vednosti, brez sklicevanja na neko presežno vednost. Hlapci verjamejo, da gospodarji nekaj o nečem vedo, česar sami ne, npr. o ključu do uspeha, kaj naj bi uspeh sploh bil in v končni instanci, kako se znebiti (kastracijske) tesnobe. Tudi religije delujejo na podoben način, le da se v transfernih odnosih znotraj strogo urejenih hiearhij ustvarjajo dogme (v kapitalizmu poslovne skrivnosti), češ da je neka vednost dosegljiva in mogoča le za izbrane.
        <br><br>
        Transferni odnos je za S. Freuda čista nevrotična iluzija. Človek se lahko izključno z razmišljanjem osvobodi gospodarja, ki ga je ustvaril sam. Človek mora razmišljati o sebi in svoji identiteti, če noče biti hlapec (simptomov). Seveda lahko ne razmišlja tudi zato, ker je pač gospodar. Vsaka vednost je namreč zastopnica simbolne moči. Lahko jo uporablja za vzpostavljanje gospostva in dominance ali pa povečevanje emancipacije in enakopravnosti med ljudmi.`
    },
    identity: {
        'sl': `Obstajajo matematična, mentalna in fizična realnost. Prva je sestavljena iz univerzalnih <i>odnosov</i> med simboli; raziskujejo jo predvsem matematiki. Druga je sestavljena iz vsega, kar lahko inteligenca ustvari z razmišljanjem. To so ideje (npr. o ljubezni, sovraštvu), koncepti (mentalni konstrukti), domišljijska bitja, sanje, fantazije, ideje o sebi (identiteta); raziskujejo jo predvsem psihologi. Fizična realnost je sestavljena iz fizične snovi, torej atomov, molekul; raziskujejo jo predvsem fiziki.
        Omenjene tri realnosti so med seboj povezane, vendar ne vemo nič o tem, kaj jih povezuje. Naštejem nekaj primerov. Točka in premica iz matematične ne obstajata v fizični realnosti. Neskončnosti iz matematične ne moremo misliti (same neskončnosti si ne moremo predstavljati, lahko pa razmišljamo o konceptu neskončnosti, točke). Krog in število pi ne obstajata v fizični realnosti, le v matematični. Bitja, ki si jih lahko predstavljamo, npr. božiček in minotaver, ne obstajajo v fizični realnosti. V fizični realnosti lahko človek trpi, npr. kot simptom psihosomatike, kljub temu, da mu v isti realnosti ne manjka nič. Fizična in matematična obstajata tudi po tem, ko mentalna realnost preminulega človeka preneha obstajati.
        <br><br>
        Temu, kar povezuje omenjene tri realnosti, pravimo <i>skrivnosti</i>. Lastnost teh je, da jih ne moremo raziskovati. Obstajajo le šarlatani, ki domnevno nekaj vedo o skrivnostih, npr. duhovih, kozmičnih energijah, usodah. 
        Vsaka vednost, npr. o tem, kdo smo, je del mentalne realnosti. Ne obstaja meja vednosti, ki jo pozneje odkrijemo in razložimo. Sama razlaga (simbolizacija) dobesedno ustvarja mejo, ki je ne more prekoračiti. Meja je sam način interpretiranja tega <i>onkraj</i>. Skrivnosti zato niso demonske, ampak so posledice in učinki razlage sveta, prav gotovo pa praktičen kraj za manipulacijo. Razvoj para pojavov je premosorazmeren z upadom odgovornosti.
        <br><br>
        Simboli, npr. slovenske besede, so umetni, so del vednosti, v naravi jih ni. Ustvarjamo jih s tem, ko jim v medsebojnih odnosih pripisujemo pomen. Lastnost simbolov, npr. vseh besed in znakov v vseh jezikih, je, da jim ne more nikoli nihče dokončno določiti pomena; vedno ostaja prostor za ponovno določitev le tega. Ker je simbolno polje (trenutni kolektivni konsenz glede tega, kaj nekaj pomeni) med ljudmi dinamično, je potrebno pomen simbolov poustvarjati.
        Religije izkoriščajo omenjeno lastnost simbolov. Tisti, ki imajo moč (vse religije so urejene hierarhično), artikulacije iz svetih knjig interpretirajo tako, da bodo svojo moč ohranjali. Simbolno polje tako zaprejo, ga naredijo nedotakljivega. Ne razumejo tega, da je potrebno pomen in smisel dejanj, npr. Jezusa, znova in znova ustvarjati. Po domače. Tudi če bi za Jezusom nekdo hodil z diktafonom in sproti dokumentiral vse, kar je pač mogoče dokumentirati, bi se ljudje s časom nestrinjali glede pomena in smisla, npr. "Ko je Jezus rekel tole, je ubistvu mislil tole.". Z argumentom moči bodo ustvarili (in ohranili) tiste pomene, ki jim ohranjajo (politično) moč.
        <br><br>
        Omenjena lastnost simbolov pomeni tudi, da ne more nikoli nihče nečesa artikulirati tako, da tega zagotovo nikoli nihče ne interpretira kot travmatično. Vsaka beseda ima toliko razsežnosti, da je lahko travmatična. Travma je nujna. Nenasilna komunikacija tako v strogem pomenu besede ne obstaja, saj mora nekdo, če želi z drugim komunicirati, simbolno polje drugega nujno razdreti; nekaj mora artikulirati tako, da bo za drugega prva interpretacija tega nujno nasilna, saj bo premaknila meje njegove vednosti. Po domače. Tudi tišino Bude pod cvetočo češnjo lahko nekdo z drugače razvito strukturo osebnosti (npr. borderline) razume kot "Aha, Buda zagotovo ve, da me njegova spokojna tišina spravlja v stisko; nekaj ima za bregom, želi mi slabo." Nenasilna (zaprta, saj je že vnaprej določeno, kaj je nasilno in kaj ni) komunikacija (v manj strogem pomenu besede) pa le vzpostavlja in ohranja sorazmerje moči, kar s pridom izkorišča tudi kapitalizem.
        <br><br>
        Carl G. Jung pravi, da je človek <i>simbolno bitje</i>. Kaj to pomeni? To pomeni, da je človek zlasti umetno bitje. Ne pomeni, da ni tudi naravno (v biološkem smislu), ampak da je predvsem umetno. Identiteta človeka, ideja o samemu sebi, ni nastala kar sama od sebe, v vakuumu, vanj je ni vgradilo neko višje bitje, z njo se človek ne rodi in ni določena vnaprej. Ta nastaja v medsebojnih odnosih z uporabo simbolov, kateri so predpogoj vsakega učenja in posledično dojemanja sebe (v odnosu do drugih in posledično do samega sebe). Človek se ne rodi kot moralno in odgovorno bitje, bitje ki že ljubi; to mora šele postati. Prav tako ne more narediti nič, da ne bi bil subjekt (podložnik) besedi.
        <br><br>
        Ker pomen simbolov ne more biti nikoli dokončno določen, tudi identiteta človeka ne more biti nikoli dokončno določena (po teorijah nekaterih psihoanalitičnih paradigm je otrok prvo simbiotično zlit s svojo materjo, je ujetnik Enosti, popolnosti, določljivosti, celote, velike Matere; ojdipov kompleks omenjeno enost razcepi, s čimer se posledično razcepi tudi identiteta otroka, rodita se nadjaz in kastracijska tesnoba). Človek ne more nikoli dokončno definirati sebe (ali drugega človeka): sam poskus definicije spreminja objekt, ki ga poskuša definirati. Temu procesu kot dekonstrukciji in rekonstrukciji ideje o sebi (in smislu, pomenu; "Kaj pomenim za koga?", "Kaj je moj smisel?") lahko rečemo psihoanaliza. 
        </br></br>
        Nezmožnost človeškega bitja, da določi lastno identiteto, je univerzalna. Gre za univerzalni hendikep človeka. Človek <i>po svoji naravi</i> nima nobenega vnaprej določenega kraja, kjer bi se oblikovala in izoblikovala njegova identiteta, kar pomeni, da je vsaka (družbena, imaginarna) identiteta le začasna, iluzorna, fiktivna ali rezultat oz. učinek začasnih interpretacij, naključnih konceptov, problematičnih razlag, propagandnih mnenj, preživelih zgodovin, z oblastjo obremenjenih politik, vprašljivih kulturnih praks, oholih oblastnih razmerij in ne zvezd, bibavice, genov ali kozmičnih energij. Univerzalni hendikep je logično nujen, kar pomeni, da smo v odnosu do hendikepa vsi enaki, vsakdo je v tem enak vsakemu drugemu človeku. Drugi je zaradi tega vedno tujec, ki ga ne moremo dokončno razumeti. 
        </br></br>
        Vsi smo enaki tudi v odnosu do smrti - z njo se bo moral vsak soočiti popolnoma sam. Človek lahko obstaja le kot bitje negotovosti in verjetij, s tem pa je tudi bitje in subjekt nedoločljivosti in mnogo dimenzionalnosti sveta, zmožen ustvarjati smisel, pomen in simbolno identiteto. Slednja pomeni odpoved imaginarnim poskusom, da bi določil lastno identiteto in jo uveljavil v družbenem polju (na račun drugih). Pomeni vztrajanje v odprtosti, nesklenjenosti, travmatičnosti, nedoločljivosti (sveta in vsake mogoče identitete).
        `
    },
    other: {
        'sl': `Pogosto lahko slišimo izjavo <i>"Vsak mora poskrbeti zase"</i>. Vse lepo in prav, ampak ni nujno, da je v skrbi zase karkoli etičnega.
        </br></br>
        Eden izmed svetov, ki ga lahko z uporabo domišljije izpeljemo iz omenjene izjave, je tekmovalen, osamljen, individualen, morda empatičen, vendar ne sočuten, brez koncepta skupnosti in ljubezni, ki ni narcistična (<i>skrb zase preko drugega</i>). Le zakaj bi bila skupnost (v nečem smo skupaj; v odnosu do nečesa smo vsi enaki) potrebna, če pa se ljudje, ki skrbijo le zase, obnašajo, kot da drug drugega ne potrebujejo, kot da skupnega imenovalca med njimi sploh ni, kot da ne živijo na istem planetu.
        </br></br>
        Če pomislimo, že brez da prej beremo teorije razvojne in <i>self</i> psihologije, ugotovimo, da smo ljudje drug od drugega odvisni (odvisnost ni isto kot zasvojenost) in da si željo drugega želimo, zaradi česar vsakodnevno vstopamo v medsebojne odnose. Že potreba po skupnem jeziku (iz katerega po lacanovski psihoanalitični teoriji vznikne zavest) sloni na premisi, da se potrebujemo in da si želimo biti željeni. Potrebujemo priznanje/pripoznanje nas samih, to pa lahko ponudi le drugi človek. Sami smo drugi za druge ljudi, ki nas potrebujejo kot dobre poslušalce. Vsak človek namreč je trpel, trpi ali bo trpel.
        </br></br>
        Zakaj torej ne bi skrbeli drug za drugega? Česa se tako bojimo oz. zakaj je izjava "Vsak mora poskrbeti zase" le manifestacija strukturne travme?
        </br></br>
        Del človekovega psihičnega aparata je tudi ego, ki spontano verjame, da je on sam s svojimi partikularnimi željami v središču. Ne le, da ni v središču človeka, temveč je objekt okoli subjekta, ki je podrejen jeziku. Narava človekove svobode je namreč bistveno drugačna, kot verjeme ego.
        </br></br>
        Ko se ljudje pogovarjajo na določen način, se lahko malce bolj zavejo, da je v vseh njih nekaj <i>istega</i>. Ego se malo bolj zave, da ni v središču, da se ne more zaobjeti v celoti in pojasniti brez ostanka. Ljudje lahko izkusijo tudi pogled na to isto z drugačne perspektive. Zaradi odnosa do tega univerzalnega se lahko ljudje tudi zastopijo, začutijo, lahko se postavijo v čevlje drugega, na to isto in na svet lahko pogledajo z očmi drugega, <i>drugače</i>.
        </br>
        Ker je subjekt razcepljen oz. ker je človek simbolno bitje, lahko na to isto vedno znova pogleda na drugačen način. Obstaja namreč izkušnja, zaradi katere lahko človek, s pomočjo drugega, sam nase pogleda z drugačne perspektive. Lahko spozna, da je tudi sam sebi tujec, namreč sebe pozna le do določene mere. Torej je tudi sam sebi Drugi, do katerega lahko vzpostavlja odnos in distanco. Do tega Drugega, tako kot do drugega, pa ima človek vedno etično dolžnost samopreseganja, pa tudi če bi bil na svetu sam, kot pravi Emmanuel Levinas. Odgovorni smo najprej Drugemu, ki je tujec v nas samih. Brez tega ni odgovornosti.
        </br></br>
        Obstaja <i>skrb za drugega preko sebe</i>. Za drugega lahko poskrbimo tako, da bomo zaradi te iste skrbi imeli korist tudi sami, saj istočasno skrbimo za Drugega. Dajemo kot končna bitja in lahko damo tudi nekaj, kar nas presega, kar pa le doda k ideji dobrega, ki bo (v nezavednem) odmevala v večnosti.
        </br></br>
        <i>"Vsak mora poskrbeti zase zato, da lahko poskrbi za Drugega (v njih samih in v drugem)."</i>`
    },
    narcissism: {
        'sl': `Ljudje pravijo, da želijo odkriti, kdo v resnici so, da želijo odkriti svoj pravi jaz. Taka želja je problematična, saj sloni na premisi, da je identiteta oz. pravi jaz že vnaprej določen, posameznik pa ga mora z <i>delom na sebi</i> izbrskati iz temnih globočin nezavednega, potem pa se ga oklepati kot pijanec plota. Menim, da si je bolje želeti spoznati, kdo vse nismo oz. kako je nastalo to, v kar trenutno verjamemo, da smo. Vztrajati v neidentiteti.
        </br></br>
        Tako kot identiteta tudi ideja o ljubezni (samopreseganju) ni "vgrajena" v človeka, ko se ta rodi. O njej se mora šele poučiti, pri čemer mora preseči samega sebe, in ni nujno, da to tudi stori. Tudi želja ega po tem ali onem objektu ni določena vnaprej; ego jo mora šele pripoznati, kar s pridom izkorišča tudi kapitalizem, ki ustvarja želje in s tem patološke potrošnike, ki iščejo svoj izgubljeni raj. Tudi vednost o tem, kako vzgajati otroka, ne pride čudežno kar sama od sebe, npr. takrat, ko mora človek otroka vzgajati, zato človek pogosto otroka vzgaja tako, kot je bil vzgajan sam.
        </br></br>
        Ko se otrok rodi, njegov ego še ne obstaja, zato je imun na želje kapitalizma. Je pa del otroka tudi tisto <i>isto</i>, o čemer sem nekaj napisal v prejšnjem zapisu. In to isto si nekaj želi in sicer sam razlog želje. Lacan pravi, da je objekt želje razlog želje. Otrok si želi, da mu drugi, npr. mama, ne stori hudega. Želi si željo matere; želi si, da si ga mati oz. Drugi želi. Pri tem sama želja ni last otroka ali matere. Otrok si želi nečesa, kar ni in ne more biti njegovo ali od matere. Otrok želi več kot to, kar je, želi se preseči. Izpolnjevanje te želje se kasneje manifestira kot identiteta.
        </br></br>
        Torej otrok želi materino željo, zaradi česar pa lahko nastane problem. Mati je namreč človek, trpljenje pa je na ontološki ravni univerzalno. Ker je tudi mati bila nekoč otrok, je tudi sama želela željo svoje matere. Izpolnitvi želje po tem presežnem lahko rečemo ljubezen. Če mater ni bila deležna ljubezni, lahko ta manjko išče v otroku (ali npr. partnerju), pri čemer nezavedno <i>skrbi zase preko drugega</i>. Otrok pripozna željo matere, želi razlog želje, zato želi želji tudi agajati. Tudi če mati želi izpolnitev svoje partikularne (ego) želje in ne izpolnitev želje otroka, bo otrok temu s svojo eksistenco (obnašanjem, čustvovanjem itd.) ugajal. Ne želi si biti zavržen, zato bo zadovoljeval materino željo, pri čemer pa bo ustvarjal svojo identiteto oz. svoj <i>lažni jaz</i>. Če se mati počuti tesnobno ali osamljeno in to premaguje tako, da v "otrokovih očeh" išče smisel svoje eksistence ("končno sem nekomu pomembna"), bo otrok tukaj objekt, preko katerega bo mati skrbela zase. Otrokov lažni jaz postaja del njegove identitete (oz. selfa), ki se bo kasneje izražal skozi npr. grandioznost, depresijo, manjko smisla, praznino itd. 
        </br></br>
        Ko otrok postaja subjekt, želja "Mati želi, da sem tak in tak" postaja del (super) ega, kasneje pa se lahko spremeni v željo "če hočem biti, moram biti dovolj dober ali pa najboljši", ki je nadvse dobrodošla v kapitalizmu, namreč zadovoljevanje te želje je lahko zelo profitabilno. Na mesto super ega (oz. Velike Matere) se lahko znajdejo tudi socialna omrežja, ki s svojimi zahtevami po všečkih in skrbno izbranih fotografijah patološko zadovoljujejo željo po <i>biti</i>.`
    }
};